
        @import 'styles/mixins.scss';
    
.badge {
    background-color: #1693f0;
    position: absolute;
    z-index: 1;
    display: inline-block;

    padding: 8px 25px;
    text-align: center;
    white-space: nowrap;
    color: white;
    border-radius: 2px 0 0 2px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3333333333;
    top: 25px;
    left: -14px;
    @include sm {
        padding: 12px 39px;
        font-size: 18px;
        left: -16px;
        height: 48px;
    }
    height: 34px;

    &:before {
        position: absolute;
        z-index: -2;
        bottom: -13px;
        left: 0;
        width: 0;
        height: 0;
        content: "";
        border-width: 7px;
        border-style: solid;
        border-color: #276ea4 #276ea4 transparent transparent;
        @include sm {
            border-width: 8px;
            bottom: -15px;
        }
    }
    &:after {
        position: absolute;
        z-index: -1;
        top: 0;
        right: -8px;
        width: 0;
        height: 0;
        content: "";
        border: 17px solid #1693f0;
        border-right: 7px solid transparent;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-color: #1693f0;
        border-right-color: transparent;
        @include sm {
            border: 24px solid #1693f0;
            right: -10px;
            border-right: 7px solid transparent;
        }
    }
}
