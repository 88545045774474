
        @import 'styles/mixins.scss';
    
.buyer {
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
    position: relative;
    border-radius: 5px;
    padding: 1.25rem;
    cursor: pointer;

    &::before,
    &::after {
        box-sizing: inherit;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
    }
    overflow: hidden;
}

.selected-buyer {
    // border: 3px solid #1693f0;
    transition: color 0.25s;
    &::before,
    &::after {
        border-radius: 5px;
        // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
        border: 3px solid transparent;
        width: 0;
        height: 0;
    }

    // This covers the top & right borders (expands right, then down)
    &::before {
        top: 0;
        left: 0;
    }

    // And this the bottom & left borders (expands left, then up)
    &::after {
        bottom: 0;
        right: 0;
    }

    &[data-selected="true"] {
        color: #1693f0;
    }

    // Hover styles
    &[data-selected="true"]::before,
    &[data-selected="true"]::after {
        width: 100%;
        height: 100%;
    }

    &[data-selected="true"]::before {
        border-top-color: #1693f0; // Make borders visible
        border-right-color: #1693f0;
        transition: width 0.25s ease-out,
            // Width expands first
            height 0.25s ease-out 0.25s; // And then height
    }

    &[data-selected="true"]::after {
        border-bottom-color: #1693f0; // Make borders visible
        border-left-color: #1693f0;
        transition: border-color 0s ease-out 0.5s,
            // Wait for ::before to finish before showing border
            width 0.25s ease-out 0.5s,
            // And then exanding width
            height 0.25s ease-out 0.75s; // And finally height
    }
}

.nextBtn {
    width: 100%;
    padding-block: 0.5rem;
    background-color: #1693f0;
    color: white;
    border-radius: 8px;
    margin-top: 1rem;

    &:hover {
        cursor: pointer;
        background-color: #107fd4;
    }

    &:disabled {
        background-color: #107fd487;
        cursor: default;
    }
}
