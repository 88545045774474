
        @import 'styles/mixins.scss';
    
.formWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;
    border-radius: 10px;
    padding-block: 70px;
    padding-top: 87px;
    overflow: hidden;
    border: 3px solid #1693f0;
    .nextBtn {
        width: 100%;
        padding-block: 0.5rem;
        background-color: #1693f0;
        color: white;
        border-radius: 8px;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            background-color: #107fd4;
        }
    }

    .cancelBtn {
        width: 100%;
        padding-block: 0.5rem;
        border: 1px solid lightgray;
        color: rgb(125, 125, 125);
        border-radius: 8px;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            border: 1px solid rgb(139, 139, 139);
        }
    }
}

.note {
    color: gray;
    width: fit-content;
    height: fit-content;
    font-size: 12px;
}
