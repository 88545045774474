
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";

.modalStyle {
    position: fixed;
    width: 100vw;
    background-color: rgba(12, 23, 39, 0.98);
    height: calc(100vh - 35px);
    height: 100dvh;

    top: 0px;
    bottom: 0;
    z-index: 1000;
}

.contentStyle {
    @media screen and (max-width: 1023px) {
        width: 90%;
        min-height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nextBtn {
        width: 100%;
        padding-block: 0.5rem;
        background-color: #1693f0;
        color: white;
        border-radius: 8px;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            background-color: #107fd4;
        }

        &:disabled {
            background-color: #107fd487;
            cursor: default;
        }
    }

    .cancelBtn {
        width: 100%;
        padding-block: 0.5rem;
        border: 1px solid lightgray;
        color: rgb(125, 125, 125);
        border-radius: 8px;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            border: 1px solid rgb(139, 139, 139);
        }
    }

    .otp-input {
        display: flex;
        width: 100% !important;
        height: 3rem;
        border: 1px solid #ccc;
        border-radius: 8px;
        font-weight: 700;

        &:focus {
            border-color: $defaultBorderColor;
        }
    }

    .btn {
        width: 100%;
        padding-block: 0.5rem;
        background-color: #1693f0;
        color: white;
        border-radius: 8px;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            background-color: #107fd4;
        }
    }
}

.modalFormText {
    width: 32vw;
    font-weight: 700;
    color: white;
    line-height: 64px;
    font-size: 40px;

    @include lg {
        left: 12%;
    }

    @media screen and (max-width: 1023px) {
        display: none;
    }
}
