
        @import 'styles/mixins.scss';
    
.slide-left {
    animation: slide-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-left {
    0% {
        transform: translateX(400px);
    }
    100% {
        transform: translateX(0);
    }
}
