
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";
.input {
    min-height: 50px;
    border: 1px solid #ccc;
    outline: none;
    font-family: "Mulish", sans-serif;
    padding: 6px 10px;
    border: 2px solid $defaultBorderColor;
    background-color: #fbfbfe;
}

.input-error {
    min-height: 50px;
    border: 2px solid red !important;
    outline: none;
    font-family: "Mulish", sans-serif;
    padding: 6px 10px;
    &:focus {
        border-color: $defaultBorderColor;
    }
}

.pac-logo:after {
    background-image: none !important;
}
