
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";

.checkbox {
    border: none;
    padding: 0;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    overflow-x: hidden;

    .option {
        text-align: center;
        margin-bottom: 10px;
        width: 100%;
        position: relative;
        border: 1px solid $defaultBorderColor;
        background-color: white;
        padding: 0 0.5rem;
        border-radius: 8px;
        input {
            visibility: hidden;
            user-select: none;
            position: absolute;
            left: -100rem;
        }
        label {
            text-align: center;
            width: 100%;
            display: block;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            display: inline-block;
            img {
                pointer-events: none;
                position: relative;
                height: auto;
            }
            .with-tooltip {
                position: absolute;
                background: #686565;
                color: #fff !important;
                padding: 3px 8px;
                border-radius: 5px;
                top: -10px;
                left: 50%;
                opacity: 0;
                transition: opacity 0.1s;
                white-space: nowrap;
                font-size: 12px;
                z-index: 4;
                line-height: 1rem;
                transform: translate(-50%, -100%);
                &::after {
                    content: " ";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border: 5px solid transparent;
                    border-top-color: #686565;
                }
            }
            &:hover {
                opacity: 1;
                .with-tooltip {
                    opacity: 1;
                    display: unset;
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            background-color: #eff5ff;
        }
    }

    .note {
        color: gray;
        width: fit-content;
        height: fit-content;
        font-size: 12px;
    }
}

.checkbox {
    .option {
        input {
            &:checked + label .box svg path {
                stroke-dashoffset: 0;
            }
            &:checked + label .box {
                background-color: #1693f0;
            }
        }
        label {
            display: flex;
            align-items: center;
            min-height: 46px;
            .box {
                display: block;
                border: 1px solid #1693f0;
                width: 15px;
                height: 15px;
                border-radius: 1px;
                cursor: pointer;
                transition: all 0.2s ease;
                margin-right: 15px;
                padding: 2px;

                svg {
                    pointer-events: none;
                    path {
                        fill: none;
                        stroke: white;
                        stroke-width: 6px;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 100;
                        stroke-dashoffset: 101;
                        transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
                    }
                }
            }
        }
    }
}
