
        @import 'styles/mixins.scss';
    
.progress-bar {
    position: relative;
    transform-style: preserve-3d;
    height: 38px;
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    width: 100%;

    .percentage {
        position: absolute;
        transition: left 0.5s ease-in-out;
        background: black;
        color: white;
        padding: 1px 7px;
        border-radius: 4px;
        bottom: 0;
        background-color: black;

        .arrow {
            border: solid transparent;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(246, 246, 246, 0);
            border-bottom-color: black;
            border-width: 4px;
            margin-left: -4px;
            // width: 4px;
            // height: 4px;
            top: 0;
            left: 50%;
            transform: translate(0%, -100%);
        }
    }

    .bar {
        top: 0%;
        left: 0;
        transform: translateY(-50%) translateZ(-1px);
        background-color: #ebf4fb;
        // @apply relative h-6px w-full rounded-10 overflow-hidden;
        height: 6px;

        span {
            position: absolute;
            left: 0;
            height: 100%;
            background-color: rgba(black, 0.5);
            border-radius: 10px;
            transition: width 0.5s ease-in-out;
        }
    }
}
