
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";
.option {
    margin-block: 1rem;
    min-height: 50px;
    border: 1px solid $defaultBorderColor;
    font-weight: 700;
    outline: none;
    padding: 6px 10px;
    border-radius: 8px;
    background-color: white;
    transition: 0.1s;
    color: #36454f;
    cursor: pointer;
    &:focus {
        background-color: #eff5ff;
    }

    &:hover {
        background-color: #eff5ff;
        transition: 0.6s;
        opacity: 1;
        cursor: pointer !important;
    }

    input {
        cursor: pointer;
        &:hover {
            cursor: pointer;
        }
    }
}

#selected {
    cursor: pointer;
    color: $defaultBorderColor;
    background-color: #eff5ff;
}

.note {
    color: gray;
    width: fit-content;
    height: fit-content;
    font-size: 12px;
}
