
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";
.select {
    min-height: 50px;
}
.select select {
    border: 1px solid $defaultBorderColor;
    outline: none;
    font-family: "Mulish", sans-serif;
    border-radius: 5px;
    padding: 6px 10px;
    height: 50px;
    opacity: 0;
    cursor: pointer;
    left: 0;
}

.placeholder {
    border: 2px solid;
    z-index: 1;
    background-color: #fff;
    height: 50px;
    pointer-events: none;
}

.arrow {
    width: 8px;
    height: 8px;
    border: 2px solid #1693f0;
    border-left: 0;
    border-top: 0;
}
.arrow-up {
    transform: rotate(225deg);
}
.arrow-down {
    transform: rotate(45deg);
}

.active {
    border-color: $defaultBorderColor;
    cursor: pointer;
}

.error {
    border-color: red;
}
.disabled {
    border-color: transparent;
    color: $disabledSelectTextColor;
    .arrow {
        border-color: $disabledSelectTextColor;
    }
}
