
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";

.listing-block {
    border: 1px solid #cfcfcf;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    position: relative;
    min-height: 220px;
    transition: all 0.2s ease-in-out;
    padding-top: 40px;

    .order {
        position: absolute;
        left: 0;
        top: 0;
        background-color: grey;
        color: white;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 4px 0;
        transition: all 0.2s ease-in-out;
        @apply text-sm;
    }
    .logo {
        width: 40%;
        padding-right: 30px;
        @include lg {
            width: 20%;
            padding: 0 40px;
        }
    }
    .content {
        padding-right: 0;
        width: 100%;
        order: 3;
        margin-top: 10px;
        h2 {
            color: $insurance-super-saver-blue-aster;
        }
        .features {
            ul {
                li {
                    svg {
                        fill: $insurance-super-saver-assault;
                        display: inline-block;
                        width: 11px;
                    }
                }
            }
        }
        @include lg {
            // padding-right: 20px;
            order: 2;
            width: 55%;
        }
    }
    .cta {
        width: 60%;
        text-align: right;
        overflow: hidden;
        a {
            background-color: $insurance-super-saver-blue-aster;
            color: white;
            display: block;
            width: 85%;
            border-radius: 5px;
            text-align: center;
            padding: 12px 10px;
            text-transform: uppercase;
            box-shadow: 5px 5px 0px #dfdfdf;
            transition: all 0.2s ease-in-out;
            margin: 0 auto 5px;
            @apply text-sm;
            &:hover {
                background-color: $insurance-super-saver-assault;
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            .check {
                width: 27px;
                height: 27px;
                border: 2px solid white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                svg {
                    height: 13px;
                    width: 13px;
                    fill: white;
                }
            }
            @include lg {
                width: 100%;
                padding: 15px 30px;
            }
        }
        .cta-state {
            text-align: center;
            font-size: 11px;
            display: block;
            margin: 0 auto;
            margin-top: 10px;
            color: #817f7f;
            text-transform: capitalize;

            font-weight: 300;

            transition: all 0.4s ease-in-out;
            @include sm {
                font-size: 13px;
            }
            @include lg {
                font-size: 14px;
                margin-top: 15px;
                opacity: 0;
                transform: translateX(250px);
            }
        }
        &.clicked {
            a {
                padding: 10px 30px;
            }
        }
        @include lg {
            width: 25%;
            order: 3;
            padding-right: 5px;
        }
    }

    &:first-child {
        background-color: rgba(#f5f5f5, 0.8);
        border-color: #d5d5d5;
        border-width: 2px;

        .order {
            background-color: $insurance-super-saver-blue-aster;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 18px;
                height: 18px;
                fill: white;
                position: absolute;
                left: 8px;
                top: 8px;
            }
        }
        .logo {
            mix-blend-mode: multiply;
        }
    }

    &:nth-child(2) {
        // border-top-width: 0;
    }

    &:last-child {
        border-bottom-width: 1px;
    }

    &:hover {
        border-color: #9a9a9a;

        box-shadow: 0px 0px 50px -20px #00000061;
        &:not(:first-child) {
            .order {
                background-color: $insurance-super-saver-assault;
            }
        }
        &:first-child {
            .cta {
                .cta-state {
                    @include lg {
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.listingModal {
        border: none;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        &:hover {
            box-shadow: none;
        }
        .content {
            @include lg {
                width: 100%;
            }
        }
        .logo {
            width: 70%;
            padding-right: 0;
            margin-bottom: 20px;
            @include lg {
                padding-left: 0;
                width: 50%;
            }
        }
        .cta {
            order: 3;
            width: 100%;
            margin-top: 30px;
        }
        @include lg {
            padding: 20px 20px;
        }
    }

    &.categoryListingModal {
        border: none;
        // background: #f7f7f7;
        border-bottom: 1px solid #eee;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        justify-content: space-between;
        min-height: inherit;
        margin: 0 -20px;
        width: calc(100% + 40px);
        @apply text-sm;
        &:last-child {
            border: none;
            // margin-bottom: 20px;
        }
        &:hover {
            box-shadow: none;
        }
        .content {
            padding-right: 0;
            h2 {
                font-size: 16px;
                margin-bottom: 0;
                font-weight: 500;
            }
            @include lg {
                width: 100%;
            }
        }
        .logo {
            width: 35%;
            padding-right: 0;
            margin-bottom: 0;

            @include lg {
                padding-left: 0;
            }
        }
        .cta-state {
            display: none;
        }
        .cta {
            order: 1;
            width: 160px;
        }
        @include lg {
            padding: 20px 20px;
            margin-top: 0;
        }
    }

    @include lg {
        padding: 20px 30px;
    }
}

@keyframes blink {
    0% {
        transform: skewX(-45deg) translateX(-130%);
    }

    100% {
        transform: skewX(-45deg) translateX(20rem);
    }
}
