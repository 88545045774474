
        @import 'styles/mixins.scss';
    
.oneBuyer {
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
    background-color: white;
    color: black;
    button {
        background-color: #1693f0;
        color: white;
    }
    a {
        color: #5793ec;
    }
}
.bestTop {
    background-color: #1693f0;
    color: white;
}

.topBuyerImage {
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
        width: 100% !important;
        height: 100% !important;
        img {
            width: auto !important;
            height: auto !important;
            min-height: 100% !important;
            min-width: auto !important;
            position: absolute !important;
        }
        & > span {
            display: none !important;
        }
    }
}
.buttonStyle {
    height: 35px;
    // width: 90%;
    @include lg {
        height: 50px;
    }
}
