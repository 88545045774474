
        @import 'styles/mixins.scss';
    
.button-arrow {
    position: relative;
    margin-left: 10px;
    overflow: hidden;
    width: 12px;
    .inner {
        position: absolute;
        width: 15px;
        height: 2px;
        background-color: white;
        display: block;
        left: 0;
        top: 50%;
        border-radius: 0 2px 2px 0;
        animation: expand 1s ease infinite;
        &::before,
        &::after {
            content: "";
            position: absolute;
            right: 0;
            background-color: white;
            height: 2px;
            width: 6px;
            border-radius: 2px 2px 2px 2px;
        }
        &::before {
            transform: rotate(40deg);
            top: -2px;
        }
        &::after {
            transform: rotate(-40deg);
            top: 2px;
        }
    }
}

@keyframes expand {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0px);
    }
}
