
        @import 'styles/mixins.scss';
    
@import "@styles/sassVariables";

.modalStyle {
    position: fixed;
    width: 100%;
    background-color: rgba(12, 23, 39, 0.98);
    height: calc(100vh - 35px);
    height: 100dvh;
    top: 0px;
    bottom: 0;
    z-index: 1000;
}
.contentStyle {
    background-color: white;
    height: 100%;

    .btn {
        width: 100%;
        padding-block: 0.5rem;
        background-color: #1693f0;
        color: white;
        border-radius: 8px;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            background-color: #107fd4;
        }
    }
}
.closeIcon {
    background-color: white;
    border-radius: 100px 0 0 100px;
    width: 67px;
    height: 75px;
    display: none;
    @include lg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    padding-left: 10px;
    cursor: pointer;
    // border: 1px solid;
}
